<template>
    <div class="border border-gray-400 overflow-hidden">
        <div class="container">
                <div
                    class="shadow-lg flex items-end w-full"
                >
                    <div class="relative w-full">
                        <img :src="fnews.link_foto" :alt="fnews.judul" class="object-cover h-96 w-full" />
                        <div class="absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full">
                            <span class="my-2 text-sm">{{ fnews.tanggal }}</span>
                            <h3 class="text-xl break-all font-bold truncate">
                                {{ fnews.judul }}
                            </h3>
                            <span class="mt-4 text-sm hover:underline cursor-pointer" @click="openDetailPage(fnews)" >Read More</span>
                        </div>
                    </div>
                </div>
        </div>
        <div class="container py-8 px-6 item-center mx-auto pt-4 pb-6" id="news">
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 p-4 py-8">
                    <div
                        v-for="sliderN in news" :key="sliderN.judul"
                        class="col-span overflow-hidden"
                    >
                        <div class="relative">
                            <img :src="sliderN.link_foto" :alt="sliderN.judul" class="object-cover h-64 w-full hover:scale-125" />
                            <div class="absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full">
                                <span class="my-2 text-sm">{{ sliderN.tanggal }}</span>
                                <h3 class="text-xl break-all font-bold truncate">
                                    {{ sliderN.judul }}
                                </h3>
                                <span class="mt-4 text-sm hover:underline cursor-pointer" @click="openDetailPage(sliderN)" >Read More</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="container py-8 bg-gray-200 item-center mx-auto px-6 pt-4 pb-6" id="news-carousel" v-show="false">
            <div class="py-8">
                <carousel
                    :perPage="3"
                    :scrollPerPage="true"
                    :navigationEnabled="true"
                    :autoplay="false"
                    :paginationEnabled="false"
                    class="grid justify-items-center item-center"
                >
                    <slide v-for="sliderM in moreNews" :key="sliderM.judul" class="w-full">
                        <div
                            class="overflow-hidden"
                        >
                            <div class="relative">
                                <img :src="sliderM.link_foto" :alt="sliderM.judul" class="object-cover h-64 w-full" />
                                <div class="absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full">
                                    <span class="my-2 text-sm">{{ sliderM.tanggal }}</span>
                                    <h3 class="text-xl break-all font-bold truncate">
                                        {{ sliderM.judul }}
                                    </h3>
                                    <span class="mt-4 text-sm hover:underline cursor-pointer" @click="openDetailPage(sliderM)" >Read More</span>
                                </div>
                            </div>
                            <!-- <router-link class="absolute inset-0" :to="'/detail-news/' + sliderM.id" /> -->
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    import { Slugify } from '@/utils';

    export default {
        name: 'NewsIndex',
        components: {
            Carousel,
            Slide,
        },

        data() {
            return {
                search: '',
                fnews: {},
                news: [],
                moreNews: [],
            };
        },

        computed: {
        },

        async created() {
            this.$store.commit('setOverlayLoading', true);

            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'News', routeName: 'NewsIndex' }
            ]);

            try {
                const response = await this.$http.get(`${this.$apiTripweWeb}/news/list`);

                const data = response.data.data.item ?? [];
                this.fnews = data.shift();
                this.news = data;
                // this.news = data.slice(0, 6);
                // this.moreNews = data.slice(6, data.length);
            } catch (error) {
                this.$toasted.global.error(error);
            }

            this.$store.commit('setOverlayLoading', false);
        },

        methods: {
            openDetailPage(news) {
                this.$router.push({
                    name: 'DetailNews',
                    params: { slug: Slugify(news.judul), news }
                });
            }
        }
    };
</script>
